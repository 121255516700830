<template>
  <sdPageHeader :title="title"> </sdPageHeader>
  <Main>
    <HorizontalFormStyleWrap>
      <sdCards headless>
        <a-form
          layout="horizontal"
          :model="formState"
          :label-col="labelCol"
          :wrapper-col="wrapperCol"
          labelAlign="left"
          :rules="rules"
          @finish="handleSubmit"
        >
          <a-form-item name="year" label="Tahun">
            <a-input-number v-model:value="formState.year" placeholder="Masukkan Tahun" />
          </a-form-item>
          <a-form-item name="name" label="Indikator Kinerja">
            <a-input v-model:value="formState.name" placeholder="Masukkan Indikator Kinerja" />
          </a-form-item>
          <a-row>
            <a-col :lg="{ span: 18, offset: 6 }" :md="{ span: 15, offset: 9 }" :xs="{ span: 24, offset: 0 }">
              <div class="sDash_form-action">
                <sdButton class="sDash_form-action__btn" type="primary" size="large" htmlType="submit">
                  {{ isLoading ? 'Harap Menunggu...' : mode }}
                </sdButton>

                <router-link :to="'/kpi/kpi-korporat/' + dateState.month + '/' + dateState.year">
                  <sdButton class="sDash_form-action__btn" type="light" size="large" htmlType="button">
                    Batal
                  </sdButton>
                </router-link>
              </div>
            </a-col>
          </a-row>
        </a-form>
      </sdCards>
    </HorizontalFormStyleWrap>
  </Main>
</template>

<script>
import { HorizontalFormStyleWrap } from '../../../components/crud/style';
import { Main } from '../../styled';
import { useStore } from 'vuex';
import { reactive, computed, onMounted } from 'vue';
import { useRoute } from 'vue-router';

const KpiKorporatForm = {
  name: 'KpiKorporatForm',
  components: { HorizontalFormStyleWrap, Main },
  data() {
    return {
      title: this.mode + ' ' + this.module,
    };
  },
  props: ['mode', 'module'],
  setup(props) {
    const { state, dispatch } = useStore();
    const { params } = useRoute();
    const isLoading = computed(() => state.crud.loading);
    const crud = computed(() => state.crud.data);
    const dateObj = new Date();

    const dateState = reactive({
      month: params.month,
      year: params.year,
    });

    const formState = reactive({
      satuan_kpi_id: undefined,
      jenis_variable_kpi_korporat_id: undefined,
      name: '',
      bobot: null,
      target_nominal: null,
      target_waktu: '',
      year: dateObj.getUTCFullYear(),
      polarisasi: undefined,
      formula: '',
      formula_description: '',
      variable_kpi_korporat_id: [],
    });

    const options = reactive({
      kpiKorporat: [],
      satuan: [],
      jenisVariableKpi: [],
      polarisasi: [
        {
          value: 'Positif',
          text: 'Positif',
        },
        {
          value: 'Negatif',
          text: 'Negatif',
        },
      ],
      variableKpiKorporat: [],
    });

    const labelCol = {
      lg: 6,
      md: 9,
      xs: 24,
    };

    const wrapperCol = {
      lg: 18,
      md: 15,
      xs: 24,
    };

    const handleSubmit = values => {
      if (values.target_waktu) {
        let my = values.target_waktu.split('-');
        values['target_waktu'] = my[0] + '-' + my[1] + '-' + '01';
      }

      if (props.mode == 'Tambah') {
        dispatch('axiosCrudSubmitDataRedirect', {
          url: 'kpi-korporat',
          data: values,
          redirect: '/kpi/kpi-korporat/' + params.month + '/' + params.year,
        });
      } else if (props.mode == 'Ubah') {
        dispatch('axiosDataUpdateRedirect', {
          id: params.id,
          url: 'kpi-korporat',
          data: values,
          redirect: '/kpi/kpi-korporat/' + params.month + '/' + params.year,
        });
      }
    };

    const rules = {
      name: [
        {
          required: true,
          message: 'Harap Diisi',
          trigger: 'blur',
        },
      ],
    };

    const filterSelect = (input, option) => {
      return option.children[0].children.toLowerCase().indexOf(input.toLowerCase()) >= 0;
    };

    onMounted(() => {
      dispatch('axiosCrudGetData', 'options-kpi-korporat').then(() => {
        options.kpiKorporat = crud.value;
      });

      dispatch('axiosCrudGetData', 'options-satuan-kpi').then(() => {
        options.satuan = crud.value;
      });

      dispatch('axiosCrudGetData', 'options-jenis-variable-kpi').then(() => {
        options.jenisVariableKpi = crud.value;
      });

      if (props.mode == 'Ubah') {
        dispatch('axiosCrudGetData', 'options-variable-kpi-korporat-unused-owned/' + params.id).then(() => {
          options.variableKpiKorporat = crud.value;
        });

        const data = {
          url: 'kpi-korporat',
          id: params.id,
        };

        dispatch('axiosSingleDataGet', data).then(() => {
          Object.keys(crud.value).forEach(key => {
            formState[key] = crud.value[key];
          });
        });
      } else {
        dispatch('axiosCrudGetData', 'options-variable-kpi-korporat-unused').then(() => {
          options.variableKpiKorporat = crud.value;
        });
      }
    });

    return {
      formState,
      labelCol,
      wrapperCol,
      handleSubmit,
      isLoading,
      rules,
      filterSelect,
      options,
      dateState,
    };
  },
};

export default KpiKorporatForm;
</script>
